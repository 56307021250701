<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <form @keydown="form.onKeydown($event)" @submit.prevent="saveClient">

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="name">
              Имя <span class="req"/>
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('name') }">
              <input class="form-control"
                     id="name"
                     name="name"
                     required
                     type="text"
                     v-model="form.name">
              <HasError :form="form" field="name"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="email">
              Email
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('email') }">
              <input class="form-control"
                     id="email"
                     name="email"
                     type="email"
                     v-model="form.email">
              <HasError :form="form" field="email"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="phone">
              Телефон <span class="req"/>
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('phone') }">
              <vue-tel-input id="phone"
                             name="phone"
                             v-bind='phone_config'
                             v-model="form.phone">
              </vue-tel-input>
              <HasError :form="form" field="phone"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="phone_two">
              Телефон #2
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('phone_two') }">
              <vue-tel-input id="phone_two"
                             name="phone_two"
                             v-bind='phone_config'
                             v-model="form.phone_two">
              </vue-tel-input>
              <HasError :form="form" field="phone_two"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="phone_three">
              Телефон #3
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('phone_three') }">
              <vue-tel-input id="phone_three"
                             name="phone_three"
                             v-bind='phone_config'
                             v-model="form.phone_three">
              </vue-tel-input>
              <HasError :form="form" field="phone_three"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label">
              Город
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('city_id') }">
              <multiselect :allow-empty="true"
                           :options="cities"
                           :searchable="true"
                           @input="() => {
                                             form.city_id = city ? city.id : null;
                                         }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Выберите город"
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           track-by="id"
                           v-model="city">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
              <HasError :form="form" field="city_id"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="passport">
              Паспорт
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('passport') }">
              <input class="form-control"
                     id="passport"
                     name="passport"
                     type="text"
                     v-model="form.passport">
              <HasError :form="form" field="passport"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="address">
              Адрес
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('address') }">
              <input class="form-control"
                     id="address"
                     name="address"
                     type="text"
                     v-model="form.address">
              <HasError :form="form" field="address"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="driver_license">
              Водительские права
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('driver_license') }">
              <input class="form-control"
                     id="driver_license"
                     name="driver_license"
                     type="text"
                     v-model="form.driver_license">
              <HasError :form="form" field="driver_license"/>
            </div>
          </div>
        </div>

        <br/>

        <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

        <Button :disabled="!validateData || form.busy"
                :form="form"
                :loading="form.busy"
                class="btn btn-lg btn-success pull-right"
                style="min-width: 100px;">
          <i class="fa fa-save"></i>
        </Button>
      </form>
    </div>
  </div>
</template>

<script>
import Form from 'vform';
import Multiselect from 'vue-multiselect';
import {VueTelInput} from 'vue-tel-input';
import phone_config from './../../assets/data/vue-tel-input-config.json';

Form.axios = API.apiClient;

export default {
  name: 'create',

  components: {
    VueTelInput,
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Добавить клиента',

    form: new Form({
      name: null,
      email: null,
      phone: null,
      phone_two: null,
      phone_three: null,
      city_id: null,
      passport: null,
      address: null,
      driver_license: null,
    }),

    cities: [],
    city: null,

    phone_config: phone_config,
  }),

  async created() {
    try {
      const cities_response = await API.apiClient.get('/cities', {
        params: this.$apiRequestParams.filters_list
      })

      this.cities = cities_response.data;
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {
    validateData: function () {
      return this.form.name && this.form.phone;
    },
  },

  methods: {
    async saveClient() {
      await this.form.post('/clients/add').then(() => {
        showSuccess();
        setTimeout(() => this.$router.push('/clients'), 600);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped>

</style>
